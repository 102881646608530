module.exports = function years() {
  const years = {
    2020: '2020',
    2021: '2021',
    2022: '2022',
    2023: '2023',
    2024: '2024',
    2025: '2025',
    2026: '2026',
    2027: '2027',
    2028: '2028',
    2029: '2029',
    2030: '2030',
  }
  return years
}
