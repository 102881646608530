<template>
  <div>
    <ul class="pagination">
      <li v-if="pagination.prev_page_url">
        <a href="#" @click.prevent="fetchPage(pagination.current_page - 1)" class="btn btn-success mr-1">&lt; Previous</a>
      </li>
      <li v-for="page in pages" :key="page" :class="{ active: page === pagination.current_page }">
        <a href="#" @click.prevent="fetchPage(page)">{{ page }}</a>
      </li>
      <li v-if="pagination.next_page_url">
        <a href="#" @click.prevent="fetchPage(pagination.current_page + 1)" class="btn btn-success">Next &gt;</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pages() {
      const from = this.pagination.current_page - 2
      const to = this.pagination.current_page + 2
      const pagesArray = []

      for (let page = from; page <= to; page++) {
        if (page > 0 && page <= this.pagination.last_page) {
          pagesArray.push(page)
        }
      }

      return pagesArray
    },
  },
  methods: {
    fetchPage(page) {
      // Emit an event to the parent component indicating the selected page
      this.$emit('page-changed', page)
    },
  },
}
</script>
